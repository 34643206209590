import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { trackEvent } from "./analytics-wrapper";
import { gradientBackgroundClasses } from "./constants";
import { GradientBorder } from "./gradient-border";
import { getCurrentSession } from "@/helpers/session";
import { classNames } from "@/helpers/tailwind-class";

interface ActionBaseProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  text: string | JSX.Element;
  classes?: string;
}

interface ActionLinkProps extends ActionBaseProps {
  link: string;
}

const defaultClasses = "block cursor-pointer rounded-full px-4 py-2 text-neutral-50";

export const actionLinkBackground = "bg-[#3C1DFF] hover:bg-[#3C1DFF]/90 disabled:bg-slate-800";

export function ActionLink({ link, text, classes = "" }: ActionLinkProps) {
  return (
    <a className={classNames(defaultClasses, actionLinkBackground, classes)} href={link}>
      {text}
    </a>
  );
}

export function ActionLinkWithIcon({ text, link = "/create" }: { text: string; link?: string }) {
  return (
    <ActionLink
      text={
        <span className="flex">
          {text}
          <ArrowLongRightIcon className="ml-2 h-6 w-6" />
        </span>
      }
      link={link}
    />
  );
}

interface ActionButtonProps extends ActionBaseProps {
  onClick: () => void;
  showGradient?: boolean;
}

export function ActionButton({
  text,
  onClick,
  showGradient = true,
  classes = "",
  ...rest
}: ActionButtonProps) {
  return (
    <button
      className={classNames(
        defaultClasses,
        showGradient ? gradientBackgroundClasses : "bg-slate-800 hover:bg-slate-700",
        classes
      )}
      onClick={onClick}
      {...rest}
    >
      {text}
    </button>
  );
}

export function CreateVideoLink() {
  return (
    <a
      href="/create"
      onClick={() => trackEvent({ eventName: "createProjectLink", action: "click" })}
    >
      <GradientBorder borderSize={4}>
        <div className="rounded-md bg-slate-900 p-2 text-sm font-medium hover:bg-slate-800">
          <div className="flex h-full w-full items-center">
            <PlusIcon className="mr-2 h-6 w-6" aria-hidden="true" />
            Create New Video
          </div>
        </div>
      </GradientBorder>
    </a>
  );
}
